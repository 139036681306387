import React from 'react'
import ContactForm from '../ContactForm/ContactForm'
import {
  ContactFormWrapper,
  ContactContainer,
  ContactTitle,
  BottomBackground,
  QuotesImage,
  StampImage,
  BottomWrapper,
  BottomDivider,
} from './styles'
import backgroundImage from '../../../images/home/rysunek.svg'
import quoteImg from '../../../images/contact/quotes.svg'
import stampImg from '../../../images/contact/stamp.svg'
import SEO from '../../Seo'

const ContactPage = () => (
  <>
    <ContactContainer>
      <SEO title="Contact"/>
      <ContactTitle>Contact us!</ContactTitle>
      <ContactFormWrapper>
        <ContactForm />
      </ContactFormWrapper>
      <StampImage src={stampImg} alt={''} title={'stamp icon'} />
    </ContactContainer>
    <BottomWrapper>
      <QuotesImage src={quoteImg} alt={''} title={'chat icon'} />
      <BottomBackground src={backgroundImage} role="presentation" />
      <BottomDivider />
    </BottomWrapper>
  </>
)

export default ContactPage
