import styled, { keyframes } from 'styled-components'
import {
  fullhd,
  largeBreakpoint,
  mediumBreakpoint,
} from '../../../config/css-constants'

export const CVInfo = styled.div`
  margin-left: 115px;
  margin-top: 50px;
  @media all and (max-width: ${mediumBreakpoint}) {
    width: 100%;
    margin: 1rem 0;
    text-align: center;
  }
`

export const Label = styled.p`
  padding-right: 10px;
  text-align: right;
  min-width: 135px;
  font-size: 22px;
  @media all and (max-width: ${mediumBreakpoint}) {
    text-align: left;
    width: 100%;
    margin: 1rem 0;
  }
`

export const Form = styled.form`
  max-width: 90%;
  margin: 0 auto;
  position: relative;
  @media all and (min-width: ${mediumBreakpoint}) {
    max-width: 85%;
  }
  @media all and (min-width: ${fullhd}) {
    max-width: unset;
  }
`

export const InputsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  margin-bottom: 50px;
  justify-content: center;
  @media all and (min-width: ${mediumBreakpoint}) {
    grid-template-columns: 1fr 1fr;
  }
`

export const Input = styled.input`
  font-style: italic;
  font-size: 16px;
  padding: 5px 5px 10px;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  background-color: unset;
  border-color: ${(props) => (props.invalid ? '#a94442' : '#000')};
  border-bottom-width: 2px;
  width: 50%;
  &:after {
    content: '';
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.7);
  }
  &:focus {
    outline: none;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    border-bottom-width: 3px;
    text-align: unset;
  }
  @media all and (max-width: ${mediumBreakpoint}) {
    width: 100%;
    text-align: left;
  }
`

export const Textarea = styled.textarea`
  display: block;
  width: 50%;
  font-size: 16px;
  border-bottom: 2px solid #000;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  padding: 5px 5px 10px;
  font-style: italic;
  min-height: 2rem;
  min-width: 50%;

  &::placeholder {
    color: rgba(0, 0, 0, 0.7);
  }
  &:focus {
    outline: none;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    border-bottom: 3px solid #000;
    text-align: unset;
  }
  @media all and (max-width: ${mediumBreakpoint}) {
    width: 100%;
    text-align: left;
  }
`

export const SubmitWrapper = styled.div`
  text-align: left;
  margin-left: 115px;
  margin-top: 20px;
  @media all and (max-width: ${mediumBreakpoint}) {
    text-align: center;
    margin-left: 0;
  }
`

export const SubmitBtnImage = styled.img`
  width: 100px;
  @media all and (min-width: ${mediumBreakpoint}) {
    display: none;
  }
`

export const SubmitBtnText = styled.p`
  display: none;
  font-size: 16px;
  @media all and (min-width: ${mediumBreakpoint}) {
    display: block;
  }
`

export const SubmitBtn = styled.button`
  background-color: unset;
  border: 1px solid #fff;
  padding: 15px 20px;
  border-radius: 20px;
  color: #fff;
  font-size: 1.4rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.4s ease;
  &:disabled {
    opacity: 0.6;
  }
  @media all and (min-width: ${mediumBreakpoint}) {
    margin-right: 30%;
    margin-top: 50px;
    min-width: 150px;
    background-color: #ffc34a;
  }
  &:hover {
    border: ${(props) => (props.disabled ? '' : '1px solid #ffc34a')};
    color: ${(props) => (props.disabled ? '' : '#ffc34a')};
    background-color: ${(props) => (props.disabled ? '' : 'unset')};
  }
`

const spin = keyframes`
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
`

export const SpinnerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media all and (min-width: ${largeBreakpoint}) {
    display: block;
  }
`

export const Spinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #357e8d;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 2s linear infinite;
  position: absolute;
  top: 20px;
  @media all and (min-width: ${largeBreakpoint}) {
    left: 30%;
  }
`

export const InputSingleWrapper = styled.div`
  position: relative;
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
  @media all and (max-width: ${mediumBreakpoint}) {
    flex-direction: column;
  }
`

export const MessageWrapper = styled(InputSingleWrapper)`
  margin-top: 65px;
`

export const MessageConfirm = styled.p`
  color: #387e8d;
  font-size: 1.2rem;
  text-align: center;
  @media all and (min-width: ${mediumBreakpoint}) {
    margin-top: 10px;
  }
`

export const FormErrorsWrapper = styled.div`
  text-align: center;
  padding: 5px;
  z-index: 2;
  @media all and (min-width: ${mediumBreakpoint}) {
    text-align: left;
    position: absolute;
  }
`
