import styled from 'styled-components'

export const List = styled.ul`
  display: block;
  width: 100%;
  min-width: 200px;
  padding-left: 10px;
`

export const ListItem = styled.li`
  list-style-type: disc;
  font-size: 0.9rem;
`
