import React from 'react'
import { List, ListItem } from './styles'

const FormErrors = ({ formErrors }) => (
  <List>
    {Object.keys(formErrors).map((fieldName, i) => {
      if (formErrors[fieldName].length > 0) {
        return (
          <ListItem key={i}>
            {fieldName} {formErrors[fieldName]}
          </ListItem>
        )
      } else {
        return ''
      }
    })}
  </List>
)

export default FormErrors
