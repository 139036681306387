import React, { Component } from 'react'
import FormErrors from '../FormErrors/FormErrors'
import { navigate } from 'gatsby-link'

import {
  Form,
  Label,
  Input,
  Textarea,
  SubmitWrapper,
  SubmitBtn,
  SubmitBtnImage,
  SubmitBtnText,
  SpinnerWrapper,
  Spinner,
  InputSingleWrapper,
  MessageConfirm,
  CVInfo,
  FormErrorsWrapper,
  MessageWrapper,
} from './styles'
import submitBtn from '../../../images/contact/submitbtn.svg'

import { contactFormEndpoint } from '../../../config/js-config'

class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    message: '',
    formErrors: {
      email: '',
      name: '',
      message: '',
    },
    emailIsValid: false,
    nameIsValid: false,
    messageIsValid: false,
    formIsValid: false,
    isLoading: false,
    confirmMessage: '',
  }

  handleInputChange = (event) => {
    const value = event.target.value
    const name = event.target.name

    this.setState({
      [name]: value,
    })
    this.validateField({ value, name })
  }

  validateField = ({ value, name }) => {
    let fieldValidationErrors = this.state.formErrors
    let emailIsValid = this.state.emailIsValid
    let nameIsValid = this.state.nameIsValid
    let messageIsValid = this.state.messageIsValid

    switch (name) {
      case 'email':
        emailIsValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        fieldValidationErrors.email = emailIsValid ? '' : ' is invalid'
        break
      case 'name':
        nameIsValid = value.length >= 5
        fieldValidationErrors.name = nameIsValid ? '' : ' is too short'
        break
      case 'message':
        messageIsValid = value.length >= 10
        fieldValidationErrors.message = messageIsValid ? '' : ' is too short'
        break

      default:
        break
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailIsValid: emailIsValid,
        nameIsValid: nameIsValid,
        messageIsValid: messageIsValid,
      },
      this.validateForm
    )
  }

  validateForm = () => {
    this.setState({
      formIsValid:
        this.state.emailIsValid &&
        this.state.nameIsValid &&
        this.state.messageIsValid,
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault()

    this.setState({
      isLoading: true,
    })

    const name = this.state.name
    const email = this.state.email
    const message = this.state.message

    const formData = {
      subject: name,
      email: email,
      content: message,
    }

    fetch(contactFormEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((result) => {
        if (result) {
          return result.json()
        }
      })
      .then(() => {
        setTimeout(() => {
          navigate('/thank-you')
        }, 500)
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          confirmMessage:
            'There was a problem with sending the message. Try again later or contact us via email',
        })
      })
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <InputSingleWrapper>
          <Label>Name *</Label>
          <Input
            invalid={this.state.formErrors.name}
            type={'text'}
            name={'name'}
            value={this.state.name}
            placeholder={'Name'}
            required
            onBlur={this.validateField}
            onChange={this.handleInputChange}
          />
        </InputSingleWrapper>
        <InputSingleWrapper>
          <Label>E-mail *</Label>
          <Input
            invalid={this.state.formErrors.email}
            type={'email'}
            name={'email'}
            value={this.state.email}
            placeholder={'Email adress'}
            required
            onBlur={this.validateField}
            onChange={this.handleInputChange}
          />
        </InputSingleWrapper>
        <MessageWrapper>
          <Label>Message *</Label>
          <Textarea
            invalid={this.state.formErrors.message}
            rows="1"
            name={'message'}
            value={this.state.message}
            placeholder={'What can we do for you?'}
            onChange={this.handleInputChange}
            onBlur={this.validateField}
            required
          />
        </MessageWrapper>
        <FormErrorsWrapper>
          <FormErrors formErrors={this.state.formErrors} />
        </FormErrorsWrapper>
        <MessageConfirm>{this.state.confirmMessage}</MessageConfirm>
        <SubmitWrapper>
          <SubmitBtn
            type={'submit'}
            disabled={!this.state.formIsValid || this.state.isLoading}
          >
            <SubmitBtnImage src={submitBtn} alt={'submit btn'} />
            <SubmitBtnText>Send</SubmitBtnText>
          </SubmitBtn>
        </SubmitWrapper>
        <CVInfo>
          <p>You want to join our team?</p>
          <p>
            Send your CV at <b>hello@deployed.pl</b>{' '}
          </p>
        </CVInfo>
        <SpinnerWrapper>
          {this.state.isLoading ? <Spinner /> : ''}
        </SpinnerWrapper>
      </Form>
    )
  }
}

export default ContactForm
